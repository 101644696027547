import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Divider, Stack } from "@mui/material"
import { LogoImage } from "../molecules/LogoImage"
import { PageHeader } from "../organisms/PageHeader"
import NewButton from "../atoms/NewButton"
import { Check } from "@mui/icons-material"
import FormField from "../atoms/FormField"
import { VenueLevelPermissions } from "../organisms/profile/VenueLevelPermissions"
import { ProfileContextProvider, useProfileContext } from "@/context/profile"

const ProfileBase: React.FC<{}> = () => {
	const { t } = useTranslation()
	const {
		userStaffEmail,
		userProfile,
		state: profileState,
		setState: setProfileState,
		canSubmit,
		updateUserProfile,
		updateUserAvatar,
	} = useProfileContext()

	return (
		<RootContainer>
			<PageHeader pageTitle={t("profile")}>
				<NewButton
					text={t("saveChange_plural")}
					buttonType="Outline"
					borderColor="primary"
					iconElement={<Check />}
					disabled={!canSubmit}
					onClick={updateUserProfile}
				/>
			</PageHeader>
			<ContentContainer>
				<ProfileSectionContainer>
					<LogoImage
						aspectRatio={[1, 1]}
						idealSize={[1000, 1000]}
						captionText={t("profileImage")}
						imageWidth={300}
						handleInputChange={updateUserAvatar}
						previewUrl={userProfile?.avatarUrl ?? undefined}
						variant="circle"
					/>
					<InfoContainer>
						<ProfileEmail>{userStaffEmail}</ProfileEmail>
						<TextContainer>
							<FormField
								label={t("legalFirstName")}
								value={profileState.firstName}
								onChange={e =>
									setProfileState(prev => ({
										...prev,
										firstName: e.target.value,
									}))
								}
								fullWidth
								required
							/>
							<FormField
								label={t("legalLastName")}
								value={profileState.lastName}
								onChange={e =>
									setProfileState(prev => ({
										...prev,
										lastName: e.target.value,
									}))
								}
								fullWidth
								required
							/>
						</TextContainer>
						<TextContainer>
							<FormField
								label={t("preferredName")}
								value={profileState.preferredName}
								onChange={e =>
									setProfileState(prev => ({
										...prev,
										preferredName: e.target.value,
									}))
								}
								fullWidth
							/>
							<FormField
								label={t("phoneNumber")}
								value={profileState.phone}
								onChange={e =>
									setProfileState(prev => ({
										...prev,
										phone: e.target.value,
									}))
								}
								fullWidth
								required
							/>
						</TextContainer>
					</InfoContainer>
				</ProfileSectionContainer>
				<StyledDivider />
				<VenueLevelPermissions />
			</ContentContainer>
		</RootContainer>
	)
}

export const Profile: React.FC<{}> = () => {
	return (
		<ProfileContextProvider>
			<ProfileBase />
		</ProfileContextProvider>
	)
}

const StyledDivider = styled(Divider)`
	border-color: ${({ theme }) => theme.colorPalette.outline.variant};
	width: 100%;
`

const ProfileEmail = styled("h4")`
	font-family: Signika-Regular;
	font-weight: 400;
	font-size: 22px;
	line-height: 28px;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	user-select: none;
`

const RootContainer = styled(Stack)`
	& {
		height: 100%;
		overflow-y: hidden;
	}
`

const ContentContainer = styled(Stack)`
	& {
		padding: 0px 48px;
		height: 100%;
		overflow-y: auto;
		gap: 48px;
		padding-top: 24px;
	}
`

const ProfileSectionContainer = styled(Stack)`
	& {
		width: 100%;
		flex-direction: row;
		gap: 48px;
		align-items: center;
	}
`
const InfoContainer = styled(Stack)`
	& {
		width: 100%;
		gap: 24px;
	}
`

const TextContainer = styled(Stack)`
	& {
		width: 100%;
		flex-direction: row;
		gap: 24px;
	}
`
