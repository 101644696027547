import NewButton from "@/components/atoms/NewButton"
import { GeneralVenueContactDetail } from "@/components/molecules/production/GeneralVenueContactDetail"
import { Add } from "@mui/icons-material"
import { styled, useTheme } from "@mui/material"
import AddVenueContactModal from "./AddVenueContactModal"
import { useEffect, useState } from "react"
import { DeleteVenueContactModal } from "./DeleteVenueContactModal"
import { PlaceholderContent } from "@/components/atoms/PlaceholderContent"
import { useTranslation } from "react-i18next"
import { useVenuesContext } from "@/context/venues"
import { useProductionContext } from "@/context/production"

export const GeneralVenueContacts: React.FC<{}> = () => {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()
	const [newContactData, setNewContactData] = useState<VenueContact[]>([])
	const { setState: setProductionState } = useProductionContext()
	const { selectedVenue } = useVenuesContext()

	useEffect(() => {
		if (selectedVenue?.contacts) {
			setProductionState(prev => ({
				...prev,
				venue: {
					...prev.venue,
					contacts: newContactData,
				},
			}))
		}
		return
	}, [newContactData, selectedVenue, setProductionState])

	useEffect(() => {
		setNewContactData(selectedVenue?.contacts || [])
	}, [selectedVenue, setNewContactData])

	return (
		<>
			<AddVenueContactModal />
			<DeleteVenueContactModal />
			<GroupContainer>
				<GroupTitleContainer>
					<h3>{t("phoneNumberAndEmailAddress_plural")}</h3>
					<NewButton
						text={t("addPhoneOrEmail")}
						iconElement={<Add htmlColor={colorPalette.primary.value} />}
						buttonType="Outline"
						borderColor="secondary"
						onClick={() =>
							setProductionState(prev => ({
								...prev,
								addContactModalOpen: true,
							}))
						}
					/>
					<small>{t("addGeneralVenueContactBlurb")}</small>
				</GroupTitleContainer>
				<GroupDetailsContainer>
					{newContactData.length === 0 ? (
						<PlaceholderContent text={t("noNumberOrEmailAdded_plural")} />
					) : (
						newContactData.map((contact, idx) => {
							return (
								<div key={idx} data-cy={`production-VenueContact-Card-${idx}`}>
									<GeneralVenueContactDetail
										contact={contact}
										idx={idx}
										setContact={setNewContactData}
									/>
									{idx + 1 !== newContactData.length && <ContactDivider />}
								</div>
							)
						})
					)}
				</GroupDetailsContainer>
			</GroupContainer>
		</>
	)
}

const GroupContainer = styled("div")`
	width: 100%;
	display: flex;
	padding-left: -48px;
	margin: 48px 0px;
`

const GroupTitleContainer = styled("div")`
	padding: 0px 48px;
	margin-left: -48px;
	min-width: 288px;
	width: 288px;

	gap: 16px;
	display: flex;
	flex-direction: column;
	align-items: start;

	& > h3 {
		width: 196px;
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font: 400 24px/32px Roboto-Regular;
		padding-bottom: 8px;
	}

	& > small {
		color: ${({ theme }) => theme.colorPalette.secondary.value};
		font: 500 11px/16px Signika-Regular;
	}
`

const GroupDetailsContainer = styled("div")`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const ContactDivider = styled("hr")`
	margin: 8px 0px;
	width: 100%;
	height: 1px;
	color: ${({ theme }) => theme.colorPalette.outline.variant};
`
