import useProductionAttachments from "@/hooks/useProductionAttachments"
import { getFileIconName, getPreviewUrl } from "@/util/attachmentUtils"
import { FileImg } from "@atoms/FileImg"
import FormField from "@atoms/FormField"
import { arrayMove } from "@dnd-kit/sortable"
import { Select } from "@molecules/Select"
import { ArrowLeft, ArrowRight, Cancel, Close } from "@mui/icons-material"
import { styled, Stack, IconButton } from "@mui/material"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

interface AttachmentDetailsProps {
	detailType: "add" | "edit"
}

const PLACEHOLDER_DATA = {
	caption: "",
	title: "",
	formattedDate: "",
	formattedTime: "",
	filename: "",
	previewUrl: undefined,
	createdBy: undefined,
}

export const AttachmentDetails = ({ detailType }: AttachmentDetailsProps) => {
	const { t } = useTranslation()
	const {
		setState,
		state: {
			currentUserName,
			addEditModalState: {
				selectedAttachmentId,
				selectedField,
				addData,
				editData,
				editFileAttachments,
				previewData,
			},
		},
	} = useProductionAttachments()

	const order = useMemo(() => {
		return previewData.findIndex(val => val.id === selectedAttachmentId)
	}, [selectedAttachmentId, previewData])

	const { caption, title, formattedDate, formattedTime, filename, previewUrl, createdBy } =
		useMemo(() => {
			const parseTime = (timestamp: string) => {
				if (timestamp === "") {
					return { formattedDate: "", formattedTime: "" }
				}
				const parsedDate = new Date(timestamp)

				return {
					formattedDate: parsedDate.toLocaleDateString("en-US", {
						month: "long",
						day: "2-digit",
						year: "numeric",
					}),
					formattedTime: parsedDate.toLocaleTimeString("en-US", {
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit",
						timeZoneName: "short",
					}),
				}
			}

			if (
				!selectedAttachmentId ||
				(detailType === "add" && !addData[selectedAttachmentId]) ||
				(detailType === "edit" && !editData[selectedAttachmentId])
			) {
				return PLACEHOLDER_DATA
			}

			if (detailType === "add") {
				const newAddData = addData[selectedAttachmentId]

				return {
					...newAddData,
					filename: newAddData.file.name,
					createdBy: currentUserName,
					previewUrl:
						getFileIconName(newAddData.file.name) === "img"
							? URL.createObjectURL(newAddData.file)
							: undefined,
					...parseTime(newAddData.timestamp),
				}
			}

			const newEditData = editData[selectedAttachmentId]
			const selectedAttachment = editFileAttachments[selectedAttachmentId]
			const nameObj = selectedAttachment.createdByUser.name
			const attachmentUserName =
				!nameObj.preferredName && !nameObj.firstName && !nameObj.lastName
					? nameObj.displayName ?? ""
					: [nameObj.preferredName ?? nameObj.firstName ?? "", nameObj.lastName ?? ""].join(" ")

			return {
				caption: newEditData.caption,
				title: newEditData.title,
				filename: selectedAttachment.file.filename,
				previewUrl: getPreviewUrl(selectedAttachment.file),
				createdBy: attachmentUserName,
				...parseTime(selectedAttachment.file.createdDate),
			}
		}, [selectedAttachmentId, addData, detailType, editData, editFileAttachments, currentUserName])

	const setValue = useCallback(
		(valueType: "caption" | "title", newVal: string) => {
			if (selectedAttachmentId === undefined) return

			if (detailType === "add") {
				setState(prevData => ({
					...prevData,
					addEditModalState: {
						...prevData.addEditModalState,
						addData: {
							...prevData.addEditModalState.addData,
							[selectedAttachmentId]: {
								...prevData.addEditModalState.addData[selectedAttachmentId],
								caption:
									valueType === "caption"
										? newVal
										: prevData.addEditModalState.addData[selectedAttachmentId].caption,
								title:
									valueType === "title"
										? newVal
										: prevData.addEditModalState.addData[selectedAttachmentId].title,
							},
						},
					},
				}))
			} else {
				setState(prevData => ({
					...prevData,
					addEditModalState: {
						...prevData.addEditModalState,
						editData: {
							...prevData.addEditModalState.editData,
							[selectedAttachmentId]: {
								...prevData.addEditModalState.editData[selectedAttachmentId],
								caption:
									valueType === "caption"
										? newVal
										: prevData.addEditModalState.editData[selectedAttachmentId].caption,
								title:
									valueType === "title"
										? newVal
										: prevData.addEditModalState.editData[selectedAttachmentId].title,
							},
						},
					},
				}))
			}
		},
		[selectedAttachmentId, detailType, setState],
	)

	const handleDeleteAttachment = useCallback(async () => {
		if (selectedAttachmentId === undefined) {
			return
		}

		if (detailType === "add") {
			setState(prev => {
				const newAddData = { ...prev.addEditModalState.addData }
				delete newAddData[selectedAttachmentId]

				return {
					...prev,
					addEditModalState: {
						...prev.addEditModalState,
						addData: newAddData,
						selectedAttachmentId: undefined,
						previewData: prev.addEditModalState.previewData.filter(
							val => val.id !== selectedAttachmentId,
						),
					},
				}
			})
		} else {
			setState(prev => ({
				...prev,
				deleteAttachmentModalOpen: true,
			}))
		}
	}, [detailType, selectedAttachmentId, setState])

	const closeDetailModal = useCallback(() => {
		setState(prevState => ({
			...prevState,
			addEditModalState: {
				...prevState.addEditModalState,
				selectedAttachmentId: undefined,
			},
		}))
	}, [setState])

	return (
		<RootContainer>
			<HeaderContainer>
				<h6>{t("attachmentDetail_plural")}</h6>
				<IconButton onClick={closeDetailModal}>
					<Close />
				</IconButton>
			</HeaderContainer>
			<FileContainer>
				<FileImg height="136px" width="136px" filename={filename || ""} previewUrl={previewUrl} />
				<FileDetailContainer>
					<p className="file-name">{filename}</p>
					<p>{formattedDate}</p>
					<p>{formattedTime}</p>
					<p>
						{t("by")}: {detailType === "add" ? currentUserName : createdBy}
					</p>
					<div onClick={handleDeleteAttachment}>
						<Cancel sx={{ height: "16px", width: "16px" }} />
						<small>{t("deletePermanently")}</small>
					</div>
				</FileDetailContainer>
			</FileContainer>

			<Select
				value={selectedField}
				disabled
				simple
				optionValues={[selectedField]}
				label={t("selectField")}
				placeholder={t("selectField")}
			/>
			<FormField
				label={t("caption")}
				value={caption}
				onChange={newVal => setValue("caption", newVal.target.value)}
			/>
			<FormField
				label={t("titleAltText")}
				value={title}
				onChange={newVal => setValue("title", newVal.target.value)}
			/>
			{order !== -1 && (
				<FooterContainer>
					<div
						className="left-arrow"
						aria-disabled={order === 0}
						onClick={
							order === 0
								? undefined
								: () =>
										setState(prev => ({
											...prev,
											addEditModalState: {
												...prev.addEditModalState,
												previewData: arrayMove(
													prev.addEditModalState.previewData,
													order,
													order - 1,
												),
											},
										}))
						}
					>
						<ArrowLeft /> <p>{t("moveLeft")}</p>
					</div>
					<p>{t("reorderAttachments")}</p>
					<div
						className="right-arrow"
						aria-disabled={order === previewData.length - 1}
						onClick={
							order === previewData.length - 1
								? undefined
								: () =>
										setState(prev => ({
											...prev,
											addEditModalState: {
												...prev.addEditModalState,
												previewData: arrayMove(
													prev.addEditModalState.previewData,
													order,
													order + 1,
												),
											},
										}))
						}
					>
						<p>{t("moveRight")}</p>
						<ArrowRight />
					</div>
				</FooterContainer>
			)}
		</RootContainer>
	)
}

const RootContainer = styled(Stack)`
	background-color: ${({ theme }) => theme.colorPalette.surface.container.low};
	gap: 16px;
	padding: 24px;
	width: 336px;
`

const FooterContainer = styled("div")`
	width: 288px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	font: 400 12px/16px Signika-Regular;
	user-select: none;

	& div {
		color: ${({ theme }) => theme.colorPalette.primary.value};
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0px;
		font: inherit;
		cursor: pointer;

		&[aria-disabled="true"] {
			color: ${({ theme }) => theme.colorPalette.secondary.fixed.dim};
			cursor: default;
		}
	}

	& .left-arrow p {
		margin-left: -4px;
		color: inherit;
	}

	& .right-arrow p {
		margin-right: -4px;
		color: inherit;
	}

	& p {
		font: inherit;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	}
`

const FileDetailContainer = styled("div")`
	min-height: 100px;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	word-wrap: break-word;

	& > .file-name {
		font: 600 14px/20px Signika-Semibold;
		max-width: 288px;
	}

	& > div {
		cursor: pointer;
		color: ${({ theme }) => theme.colorPalette.error.container.on};
		display: flex;
		align-items: center;
		gap: 4px;
		padding-top: 4px;

		& > small {
			font: 400 12px/16px Signika-Regular;
		}
	}

	& > p {
		font: 400 14px/20px Signika-Regular;
	}
`

const HeaderContainer = styled("div")`
	width: 100%;
	display: flex;
	justify-content: space-between;
	font: 500 14px/20px Signika-Medium;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};

	& > h6 {
		font: inherit;
		text-transform: uppercase;
	}

	& > .MuiButtonBase-root {
		padding: 0;
	}
`

const FileContainer = styled("div")`
	display: flex;
	justify-content: start;
	align-items: center;
	width: 100%;
	gap: 16px;
	flex-wrap: wrap;
	max-width: 288px;
`
