import { Cancel, GroupsOutlined } from "@mui/icons-material"
import NewModal from "../../molecules/NewModal"
import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from "react"
import { Box, Unstable_Grid2 as Grid, styled } from "@mui/material"
import FormField from "../../atoms/FormField"
import { Select } from "../../molecules/Select"
import FormControlLabel from "../../atoms/FormControlLabel"
import Checkbox from "../../atoms/Checkbox"
import { css } from "styled-components"
import { Add } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import Autocomplete from "../../molecules/Autocomplete"
import { USER_TITLE_KEYS } from "../../../util/constants/STAFF_CREW"
import { useStaffCrew } from "@/hooks/useStaffCrew"
import CircularProgress from "@mui/material/CircularProgress"
import { normalizeTimezone } from "@/util/dateFunctions"

const DEFAULT_FORM_INPUT: CreateUpdateStaffCrewBody = {
	firstName: "",
	nickName: "",
	middleInitial: "",
	lastName: "",
	username: "",
	phone: "",
	roleName: "",
	title: [],
	includeInTechPack: false,
}

const PERMISSION_MAP: Record<string, "venueAdmins" | "venueManagers" | "venueStaff"> = {
	"Venue Admin": "venueAdmins",
	"Venue Manager": "venueManagers",
	"Venue Staff": "venueStaff",
}

const PHONE_NUMBER_MASK = /^(\+\d{1,3}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?)*\d{1,4}$/

export const AddEditUserModal: FC<{}> = () => {
	const { t } = useTranslation()
	const {
		state: { addStaffModalOpen, editStaffModalOpen, selectedUserId, searchEmail },
		setState,
		selectedStaffCrewMember,
		productRoles,
		createStaffCrewMember,
		updateStaffCrewMember,
		deleteStaffCrewMember,
		resendInvite,
		getStaffCrewData,
		staffCrewPermissions,
		foundStaffCrewEmail,
		foundStaffCrewEmailPending,
	} = useStaffCrew()
	const [contentInvalid, setContentInvalid] = useState(false)
	const [modalType, setModalType] = useState<"addNewUser" | "editActiveUser" | "editInactiveUser">(
		"addNewUser",
	)

	const [formInput, setFormInput] = useState<CreateUpdateStaffCrewBody>(DEFAULT_FORM_INPUT)
	const autofilledInfo = useMemo(
		() => modalType === "addNewUser" && formInput.username !== "" && foundStaffCrewEmail != null,
		[modalType, formInput.username, foundStaffCrewEmail],
	)

	const existsOnCurrentVenue = useMemo(() => {
		if (!foundStaffCrewEmail) {
			return false
		}

		return (
			getStaffCrewData.find(val => val.email === foundStaffCrewEmail.user.username) !== undefined
		)
	}, [getStaffCrewData, foundStaffCrewEmail])

	const filteredRoleLevels = useMemo(() => {
		return productRoles.filter(val =>
			staffCrewPermissions.update.includes(PERMISSION_MAP[val.name]),
		)
	}, [staffCrewPermissions, productRoles])

	const editDisabled = useMemo(() => {
		return (
			modalType !== "addNewUser" &&
			selectedStaffCrewMember !== undefined &&
			selectedStaffCrewMember.roleName !== null &&
			!staffCrewPermissions.update.includes(PERMISSION_MAP[selectedStaffCrewMember.roleName])
		)
	}, [staffCrewPermissions, selectedStaffCrewMember, modalType])

	const canSubmitEdit = useMemo(() => {
		return (
			modalType !== "addNewUser" &&
			selectedStaffCrewMember !== undefined &&
			(selectedStaffCrewMember.roleName !== formInput.roleName ||
				selectedStaffCrewMember.includedInTechpack !== formInput.includeInTechPack ||
				selectedStaffCrewMember.title !== formInput.title.join("; "))
		)
	}, [
		modalType,
		selectedStaffCrewMember,
		formInput.roleName,
		formInput.includeInTechPack,
		formInput.title,
	])

	const closeModal = useCallback(() => {
		setState(prev => ({
			...prev,
			addStaffModalOpen: false,
			editStaffModalOpen: false,
		}))
	}, [setState])

	const handleSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault()

			if (addStaffModalOpen) {
				const uploadData: CreateUpdateStaffCrewBody =
					foundStaffCrewEmail != null
						? {
								...formInput,
								firstName: foundStaffCrewEmail.profile.name.firstName || "",
								lastName: foundStaffCrewEmail.profile.name.lastName || "",
								middleInitial: foundStaffCrewEmail.profile.name.middleInitial || "",
								nickName: foundStaffCrewEmail.profile.name.nickName || "",
								phone: foundStaffCrewEmail.profile.phone || "",
						  }
						: formInput

				createStaffCrewMember(uploadData, {
					onSettled: () => {
						setState(prev => ({
							...prev,
							addStaffModalOpen: false,
						}))
					},
				})
			} else if (editStaffModalOpen && selectedStaffCrewMember?.userId !== undefined) {
				updateStaffCrewMember(
					{
						userId: selectedStaffCrewMember.userId,
						updateData: formInput,
					},
					{
						onSettled: () => {
							setState(prev => ({
								...prev,
								editStaffModalOpen: false,
								selectedUserId: undefined,
							}))
						},
					},
				)
			}
		},
		[
			addStaffModalOpen,
			editStaffModalOpen,
			selectedStaffCrewMember?.userId,
			setState,
			createStaffCrewMember,
			formInput,
			updateStaffCrewMember,
			foundStaffCrewEmail,
		],
	)

	const formatDateString = (dateString: string) => {
		const date = new Date(normalizeTimezone(dateString))
		return date.toLocaleString("en-US", {
			month: "numeric",
			day: "numeric",
			year: "2-digit",

			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
			timeZoneName: "short",
		})
	}

	useEffect(() => {
		if (addStaffModalOpen && !editStaffModalOpen) {
			setFormInput(DEFAULT_FORM_INPUT)
			setContentInvalid(false)
		} else if (editStaffModalOpen && !addStaffModalOpen && selectedStaffCrewMember !== undefined) {
			setContentInvalid(false)
			setFormInput({
				username: selectedStaffCrewMember.email,
				firstName: selectedStaffCrewMember.name.firstName || "",
				nickName: selectedStaffCrewMember.name.nickName || "",
				middleInitial: selectedStaffCrewMember.name.middleInitial || "",
				lastName: selectedStaffCrewMember.name.lastName || "",
				title: selectedStaffCrewMember.title?.split("; ") || [],
				phone: selectedStaffCrewMember.phone || "",
				roleName: selectedStaffCrewMember.roleName || "",
				includeInTechPack: selectedStaffCrewMember.includedInTechpack,
			})
		} else if (!addStaffModalOpen && !editStaffModalOpen) {
			setFormInput(DEFAULT_FORM_INPUT)
		}
	}, [
		addStaffModalOpen,
		editStaffModalOpen,
		setFormInput,
		setContentInvalid,
		selectedStaffCrewMember,
	])

	useEffect(() => {
		let ignore = false

		setTimeout(() => {
			if (!ignore && formInput.username !== searchEmail && !editStaffModalOpen) {
				setState(prev => ({
					...prev,
					searchEmail: formInput.username,
				}))
			}
		}, 1500)

		return () => {
			ignore = true
		}
	}, [setState, formInput.username, searchEmail, editStaffModalOpen])

	useEffect(() => {
		const updateTitleText = () => {
			if (addStaffModalOpen && modalType !== t("addNewUser")) {
				setModalType("addNewUser")
				return
			}

			if (!editStaffModalOpen) return

			if (selectedStaffCrewMember?.hasLoggedIn === 1) {
				setModalType("editActiveUser")
				return
			}

			setModalType("editInactiveUser")
		}

		if (addStaffModalOpen || editStaffModalOpen) {
			updateTitleText()
		}
	}, [
		modalType,
		setModalType,
		addStaffModalOpen,
		editStaffModalOpen,
		t,
		selectedStaffCrewMember?.hasLoggedIn,
	])

	return (
		<NewModal
			open={
				(addStaffModalOpen || editStaffModalOpen) &&
				selectedUserId === selectedStaffCrewMember?.userId
			}
			closeModal={closeModal}
			size="lg"
			title={t(modalType)}
			titleIcon={<GroupsOutlined />}
			hint={
				modalType === "editInactiveUser" ? (
					contentInvalid ? (
						<Box
							component="span"
							color={theme => (contentInvalid ? theme.colorPalette.error.value : "inherit")}
						>
							{t("completeRequiredFieldsInRed")}
						</Box>
					) : (
						t("requiredField_plural")
					)
				) : undefined
			}
			sx={{
				"& small::before": {
					content: `"*"`,
					color: theme => (contentInvalid ? theme.colorPalette.error.value : "inherit"),
				},
			}}
			footerActions={{
				start:
					modalType === "editInactiveUser" || modalType === "editActiveUser"
						? [
								{
									iconElement: <Cancel />,
									buttonType: "Text",
									text: t("deletePermanently"),
									type: "button",
									buttonStyles: css`
										color: ${({ theme }) => theme.colorPalette.error.container.on};
									`,
									stateLayerStyles: css``,
									onClick: () => {
										deleteStaffCrewMember(selectedUserId ?? "")
										closeModal()
									},
									disabled: editDisabled,
								},
						  ]
						: undefined,
				end: [
					"Cancel",
					{
						iconElement: <Add />,
						buttonType: "Outline",
						text: modalType === "addNewUser" ? t("addNewUser") : t("saveUser"),
						borderColor: "primary",
						type: "submit",
						form: "add-staff-form",
						disabled:
							(modalType !== "addNewUser" && (editDisabled || !canSubmitEdit)) ||
							(modalType === "addNewUser" &&
								(existsOnCurrentVenue || searchEmail !== formInput.username)),
					},
				],
			}}
		>
			<Grid
				container
				component="form"
				spacing={2}
				rowSpacing={5}
				id="add-staff-form"
				onSubmit={handleSubmit}
				onInvalid={() => setContentInvalid(true)}
			>
				{modalType === "editInactiveUser" && (
					<>
						<Grid
							xs={12}
							container
							display="flex"
							justifyContent={"start"}
							alignContent={"center"}
							direction={"column"}
							gap="8px"
						>
							<SentConfirmationMessage>{t("confirmationEmailSentMessage")}</SentConfirmationMessage>
							<SendAgainDiv component="p">
								{t("didntReceiveEmail?") + " "}
								<span onClick={() => resendInvite(selectedStaffCrewMember?.email ?? "")}>
									{t("sendAgain")}
								</span>
							</SendAgainDiv>
						</Grid>
					</>
				)}
				<Grid xs={12}>
					<FormField
						label={t("emailAddress")}
						fullWidth
						value={formInput["username"]}
						onChange={e => setFormInput(prev => ({ ...prev, username: e.target.value }))}
						required
						error={formInput["username"] === "" && contentInvalid}
						type="email"
						disabled={modalType !== "addNewUser" || editDisabled}
						InputProps={{
							endAdornment:
								modalType === "addNewUser" &&
								((foundStaffCrewEmailPending && foundStaffCrewEmail !== undefined) ||
									(searchEmail !== formInput.username && formInput.username !== "")) ? (
									<StyledProgressAdornment>
										<CircularProgress size={24} />
									</StyledProgressAdornment>
								) : undefined,
						}}
					/>
					{modalType === "editInactiveUser" && (
						<UsernameHint>
							{t("lastConfirmationEmailSentOn", {
								date: selectedStaffCrewMember?.lastNotifiedSignup
									? formatDateString(selectedStaffCrewMember.lastNotifiedSignup)
									: "N/A",
							})}
						</UsernameHint>
					)}
					{modalType === "addNewUser" && existsOnCurrentVenue && (
						<UsernameHint className="errorText">{t("emailAlreadyExists")}</UsernameHint>
					)}
				</Grid>
				<Grid xs={12} container>
					<Grid xs={6}>
						<FormField
							label={t("legalFirstName")}
							fullWidth
							required
							value={
								autofilledInfo
									? foundStaffCrewEmail?.profile.name.firstName ?? ""
									: formInput["firstName"]
							}
							onChange={e => setFormInput(prev => ({ ...prev, firstName: e.target.value }))}
							error={formInput["firstName"] === "" && contentInvalid}
							disabled={editDisabled || autofilledInfo || modalType !== "addNewUser"}
						/>
					</Grid>
					<Grid xs={6}>
						<FormField
							label={t("preferredName")}
							fullWidth
							value={
								autofilledInfo
									? foundStaffCrewEmail?.profile.name.nickName ?? ""
									: formInput["nickName"]
							}
							onChange={e => setFormInput(prev => ({ ...prev, nickName: e.target.value }))}
							disabled={editDisabled || autofilledInfo || modalType !== "addNewUser"}
						/>
					</Grid>
				</Grid>
				<Grid xs={12} container>
					<Grid xs={2.5}>
						<FormField
							label={t("middleInitial")}
							fullWidth
							value={
								autofilledInfo
									? foundStaffCrewEmail?.profile.name.middleInitial ?? ""
									: formInput["middleInitial"]
							}
							onChange={e => setFormInput(prev => ({ ...prev, middleInitial: e.target.value }))}
							inputProps={{
								maxLength: 1,
								mask: "(0)999 999 99 99",
							}}
							disabled={editDisabled || autofilledInfo || modalType !== "addNewUser"}
						/>
					</Grid>
					<Grid xs={9.5}>
						<FormField
							label={t("legalLastName")}
							fullWidth
							required
							value={
								autofilledInfo
									? foundStaffCrewEmail?.profile.name.lastName ?? ""
									: formInput["lastName"]
							}
							onChange={e => setFormInput(prev => ({ ...prev, lastName: e.target.value }))}
							error={formInput["lastName"] === "" && contentInvalid}
							disabled={editDisabled || autofilledInfo || modalType !== "addNewUser"}
						/>
					</Grid>
				</Grid>
				<Grid xs={12} container>
					<Grid xs={6}>
						<Autocomplete
							selectLabel={t("title")}
							required={formInput.title.length === 0}
							options={USER_TITLE_KEYS.map(val => t(val))}
							fullWidth
							slotProps={{
								paper: {
									sx: {
										maxHeight: "200px",
									},
								},
							}}
							sx={{
								width: "280px",

								"& > .MuiFormControl-root > .MuiInputLabel-root": {
									".MuiFormLabel-asterisk": {
										visibility: "hidden",
										display: "none",
									},
									"&::after": {
										paddingInlineStart: "4px",
										content: "'*'",
									},
								},
							}}
							multiple
							value={formInput.title}
							onChange={(_, newVal) => {
								setFormInput(prev => ({
									...prev,
									title: newVal,
								}))
							}}
							disabled={editDisabled}
							error={formInput["title"].length === 0 && contentInvalid}
						/>
					</Grid>
					<Grid xs={6}>
						<FormField
							label={t("phoneNumber")}
							fullWidth
							required
							value={autofilledInfo ? foundStaffCrewEmail?.profile.phone ?? "" : formInput["phone"]}
							onChange={e =>
								setFormInput(prev => ({
									...prev,
									phone: e.target.value,
								}))
							}
							error={
								(formInput["phone"] === "" || !PHONE_NUMBER_MASK.test(formInput["phone"])) &&
								contentInvalid
							}
							type="tel"
							disabled={editDisabled || autofilledInfo || modalType !== "addNewUser"}
						/>
					</Grid>
				</Grid>
				<Grid xs={12} container>
					<Grid xs={6}>
						<Select
							label={`${t("permissionLevel")}`}
							optionValues={
								editDisabled
									? productRoles.map(val => val.name)
									: filteredRoleLevels.map(val => val.name)
							}
							value={formInput["roleName"]}
							onChange={e => setFormInput(prev => ({ ...prev, roleName: e.target.value }))}
							fullWidth
							error={formInput["roleName"] === "" && contentInvalid}
							required
							simple
							disabled={editDisabled}
						/>
					</Grid>
					<Grid xs={6} alignContent="center">
						<FormControlLabel
							label={t("includeUserInTechPack")}
							control={
								<Checkbox
									checkboxSize={22}
									checked={formInput["includeInTechPack"]}
									onChange={e =>
										setFormInput(prev => ({ ...prev, includeInTechPack: e.target.checked }))
									}
									disabled={editDisabled}
								/>
							}
							disabled={editDisabled}
						/>
					</Grid>
				</Grid>
			</Grid>
		</NewModal>
	)
}

const UsernameHint = styled("small")`
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	font-family: Signika-Regular;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	width: 100%;
	padding: 4px 16px 0px 16px;
	margin-bottom: -20px;
	height: 20px;
	display: block;

	&&::before {
		content: none;
	}

	&.errorText {
		color: ${({ theme }) => theme.colorPalette.error.value};
	}
`

const StyledProgressAdornment = styled("div")`
	height: 24px;
	width: 24px;

	& > .MuiCircularProgress-root > svg.MuiCircularProgress-svg {
		color: ${({ theme }) => theme.colorPalette.primary.value};
		overflow: visible;
	}
`

const SentConfirmationMessage = styled(Box)`
	background-color: ${({ theme }) => theme.colorPalette.error.container.value};
	padding: 8px 16px;
	border-radius: 4px;
	font: 500 14px/20px Signika-Medium;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.colorPalette.error.container.on};
	margin-top: 16px;
`

const SendAgainDiv = styled(Box)`
	font: 500 14px/20px Signika-Medium;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	text-align: center;
	letter-spacing: 0.1px;
	margin-bottom: 8px;

	& span {
		font: 600 14px/20px Signika-Semibold;
		color: ${({ theme }) => theme.colorPalette.primary.value};
		cursor: pointer;
		opacity: 1;

		&:hover {
			opacity: 0.75;
		}

		transition: opacity 75ms ease-out;
	}
`
