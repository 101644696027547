import NewButton from "@/components/atoms/NewButton"
import { ProductionPageTranslationKey } from "@/components/views/Production"
import mediaQuery from "@/util/mediaQuery"
import { useProductionContext } from "@/context/production"
import { Check } from "@mui/icons-material"
import { styled } from "@mui/material"
import { useTranslation } from "react-i18next"

export const SectionHeader: React.FC<{ pageTranslationKey: ProductionPageTranslationKey }> = ({
	pageTranslationKey,
}) => {
	const { t } = useTranslation()
	const { saveProductionTab, tabHasChanges } = useProductionContext()
	return (
		<SectionContainer>
			<TitleContainer>
				<Title>{t(pageTranslationKey)}</Title>
				<NewButton
					buttonType="Outline"
					text={t("saveChange_plural")}
					iconElement={<Check />}
					onClick={saveProductionTab}
					disabled={!tabHasChanges}
				/>
			</TitleContainer>
			<TitleHr />
		</SectionContainer>
	)
}

const SectionContainer = styled("div")`
	gap: 12px;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 48px;
	padding-top: 40px;

	${mediaQuery("md")`
		padding: 24px 16px;
	`}
`

const TitleContainer = styled("div")`
	display: flex;
	justify-content: space-between;
`

const Title = styled("h3")`
	font-family: Roboto-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`

const TitleHr = styled("hr")`
	color: ${({ theme }) => theme.colorPalette.outline.inverseVariant};
	width: 100%;
`
